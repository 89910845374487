<!-- 平台账单内容页 -->
<template>
  <div>
    <blockTitle title="平台账单内容页"></blockTitle>
    <el-card shadow="never">
      <div class="card-content">
        <div class="text-xl font-bold pt-2 pb-4 border-b mb-4">
          {{ billObj.billNo }} 账单明细
        </div>
        <div
            class="text-lg font-bold mb-2 border-b border-gray-200 border-dashed pb-2 mt-8"
        >
          账单内容
        </div>
        <el-table
            :summary-method="getSum"
            show-summary
            :data="billProject"
            style="width: 100%"
        >
          <el-table-column prop="projectType" label="项目类型">
            <template v-slot="{ row }">
              {{
                $store.getters.projectTypeListFilter({
                  projectType: row.projectType
                }).label
              }}
            </template>
          </el-table-column>
          <el-table-column prop="billProjectId" label="产品规格">
            <template v-slot="{ row }">
              <div>{{ row.projectName }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="quantity" label="数量">
            <template v-slot="{ row }">
              {{ row.quantity }}
            </template>
          </el-table-column>
          <el-table-column prop="money" label="总金额">
            <template v-slot="{ row }">
              {{ row.money }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {
      billObj: {},
      billProject: [],
      allBillProjectList: []
    }
  },
  methods: {
    getPtBillProjectByBillNo (billNo) {
      this.$api.platform.ptBillProjectByBillNo({ billNo }).then(res => {
        this.billProject = res.data
      })
    },
    /**
     * 计算合计
     */
    getSum ({
      columns,
      data
    }) {
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总价'
          return
        }
        if (column.property === 'money') {
          if (data.length > 0) {
            const moneyArr = data.map(item => item.money)
            sums[index] = moneyArr.reduce((prev, curr, index) => {
              const row = data[index]
              if (row.billProjectId) {
                // 如果没有选择产品规格 不参与合计计算
                return prev + curr
              } else {
                return prev
              }
            })
            sums[index] += ' 元'
          }
        }
      })
      return sums
    },
    getBillProjectList () {
      this.$api.findAll('billProject').then(res => {
        this.allBillProjectList = res.data
      })
    },
    getData () {
    }
  },
  mounted () {
    if (this.$route.query.billNo) {
      this.getBillProjectList()
      this.getData()
      this.getPtBillProjectByBillNo(this.$route.query.billNo)
    }
  }
}
</script>
